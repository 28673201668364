<template>
  <div id="mobile_index">
    <!-- header -->
    <MHeader />

    <!-- banner -->
    <div class="bannner">
      <swiper :options="swiperOption">

        <swiper-slide>
          <div
            class="banner_box"
            style="
              background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_banner_d_1.jpg');
            "
          ></div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="banner_box"
            style="
              background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_banner_d_2.jpg');
            "
          ></div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="banner_box"
            style="
              background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_banner_d_3.jpg');
            "
          ></div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="banner_box"
            style="
              background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_banner_d_4.jpg');
            "
          ></div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="banner_box"
            style="
              background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_banner_d_5.jpg');
            "
          ></div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="banner_box"
            style="
              background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_banner_d_6.jpg');
            "
          ></div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="company_introduce">
      <div class="title">企业介绍</div>
      <div class="intro_box">
        让茶集团创立于2020年，是一家专注“方便健康茶”的现代专业快消品公司。
      </div>
      <div class="intro_box" style="margin: 4.133vw auto 0">
        旗下拥有无糖茶饮料品牌“让茶”和中式茶包品牌“他山集”。公司以“方便”和“好喝”为经营理念，将传统茶叶以瓶装茶和袋泡茶的现代便捷形式呈现，为年轻群体提供安全、健康、方便的茶饮方式。
      </div>
      <div class="title" style="margin: 6.533vw auto 4vw auto">企业愿景</div>
      <div class="intro_box">让茶陪你每一天</div>
      <div class="title" style="margin: 6.667vw auto 4vw auto">企业使命</div>
      <div class="intro_box" style="margin-bottom: 6.667vw">
        致力于为全球消费者提供方便好喝的茶饮产品
      </div>
    </div>

    <!-- 高山花茶系列 -->
    <div class="series_box series_bg_huacha" @click="toSeries('e')">
      <div class="title" style="top: 29.333vw; color: #3d6c18">高山花茶系列</div>
      <div class="img_box" style="width: 29.4vw">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_huacha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="dec_box">
        <div class="dec_left">
          <span>·</span>
          <span>自然清新</span>
        </div>
        <div class="dec_right">
          <span>·</span>
          <span>茶味自然</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>

    <!-- 高山系列 -->
    <div class="series_box series_bg_c" @click="toSeries('d')">
      <div class="title" style="top: 29.333vw; color: #3d6c18">高山纯茶系列</div>
      <div class="img_box" style="width: 29.4vw">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_p_c.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="dec_box">
        <div class="dec_left">
          <span>·</span>
          <span>喝好茶</span>
        </div>
        <div class="dec_right">
          <span>·</span>
          <span>高山茶</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>

    <!-- 草本系列 -->
    <div class="series_box series_bg_a" @click="toSeries('c')">
      <div class="title" style="top: 29.333vw; color: #3d6c18">草本茶系列</div>
      <div class="img_box" style="width: 29.4vw">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_p_a.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="dec_box">
        <div class="dec_left">
          <span>·</span>
          <span>清爽甘甜</span>
        </div>
        <div class="dec_right">
          <span>·</span>
          <span>少苦涩</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>

    <!-- 无糖果味茶系列 -->
    <div class="series_box series_bg_b" @click="toSeries('a')">
      <div class="title" style="top: 29.333vw; color: #ad1d3b">
        无糖果味茶系列
      </div>
      <div class="img_box" style="width: 27.4vw; top: 25.8vw">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/m_guocha_putao.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="dec_box">
        <div class="dec_left" style="color: #ad1d3b">
          <span>·</span>
          <span>0糖0能量</span>
        </div>
        <div class="dec_right" style="color: #ad1d3b">
          <span>·</span>
          <span>真茶真好喝</span>
        </div>
      </div>
      <div class="learn_more">查看详情 ></div>
    </div>
    <!-- 视频 -->
    <div class="video_box">
      <video
        poster="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/video_cover_img.jpg"
        controls
        id="video_introduce"
      >
        <source
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/website_a.mp4"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="introduce_box">
      <div class="introduce_inside">
        <div class="dec">
          “让茶”即“请你来喝茶”.以“茶”为载体，让中国传统礼仪文化以及精致茶饮生活方式，在年轻族群中，焕发现代风貌.
        </div>
        <div class="title">关于让茶</div>
      </div>
      <div class="model"></div>
    </div>

    <MobileConncatUs />

    <MFooter />
  </div>
</template>

<script>
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import MobileConncatUs from "@/components/MobileConncatUs";
export default {
  name: "MobileIndex",
  components: {
    MHeader,
    swiper,
    swiperSlide,
    MFooter,
    MobileConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        effect: "fade",
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
          renderBullet: function(index, className) {
            return '<span class="' + className + '">' + "</span>";
          },
        },
        // 设置点击箭头
        /* navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick:true
        }, */
      },
      flag: false,
      style_width: "opacity:0;",
      flag_form: false,
      form: {
        customer_name: "",
        customer_phone: "",
        customer_company: "",
        customer_position: "",
        customer_wechat_number: "",
        customer_email: "",
        remark: "",
      },
      rules: {
        customer_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        customer_phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入详细说明需求", trigger: "blur" },
        ],
      },
      is_submit_success: false,
    };
  },
  methods: {
    toSeries(type) {
      this.$router.push(`/mrangcha/list?type=${type}`);
      sessionStorage.setItem("active_index", 1);
    },
    learn_more() {
      this.$router.push(`/mrangcha/list`);
      sessionStorage.setItem("active_index", 1);
    },
    look_phone() {
      window.open("tel:400-807-0177", "_blank");
    },
    show_message_box() {
      this.flag_form = true;
    },
    hidden_message_box() {
      this.flag_form = false;
      this.form = {
        customer_name: "",
        customer_phone: "",
        customer_company: "",
        customer_position: "",
        customer_wechat_number: "",
        customer_email: "",
        remark: "",
      };
      this.is_submit_success = false;
    },
    submit() {
      let paramas = this.form;
      Object.keys(paramas).map((key) =>
        !paramas[key] ? delete paramas[key] : ""
      );
      addCustomerMessage(paramas).then((res) => {
        if (res.data.code == 200) {
          this.is_submit_success = true;
        } else {
          Toast.fail(res.data.message[0]);
        }
      });
    },
  },
  created() {},
  mounted() {
    let vid = document.getElementById("video_introduce");
    /* vid.play(); */
  },
};
</script>

<style scoped lang="less">
#mobile_index {
  margin-top: 18.667vw;
  overflow: hidden;

  .bannner {
    width: 100vw;
    height: calc(100vh - 18.667vw);
    /* margin-top: ; */
    background: #d7d7d7;
    .banner_box {
      width: 100%;
      height: calc(100vh - 18.667vw);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    & /deep/ .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      /* border: #fff; */
      border-radius: 50%;
      background: #fff;
      opacity: 1;
    }
    & /deep/ .swiper-pagination-bullet-active {
      background: #45B035;
    }
    & /deep/ .swiper-slide-active {
      .banner_box {
        transform: scale(1.05);
        animation-name: banner_box;
        animation-duration: 4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }
    }
    @keyframes banner_box {
      from {
        transform: scale(1.05);
      }
      to {
        transform: scale(1);
      }
    }
  }
  .company_introduce {
    overflow: hidden;
    .title {
      width: 23.2vw;
      height: 8vw;
      line-height: 8vw;
      text-align: center;
      margin: 6.667vw auto 3.867vw auto;
      background: #45B035;
      color: black;
      font-weight: 500;
      font-size: 16px;
    }
    .intro_box {
      width: 85.467vw;
      font-size: 13px;
      font-weight: 400;
      margin: 0 auto;
      color: black;
      line-height: 5.6vw;
      text-align: center;
      letter-spacing: 0.667vw;
    }
  }
  .series_box {
    width: 100%;
    height: 144vw;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 1.6vw;
    position: relative;
    .title {
      font-size: 6.4vw;
      font-weight: bold;
      color: #425a0e;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      letter-spacing: 6px;
      position: absolute;
      top: 23.333vw;
      left: 7.2vw;
    }
    .img_box {
      width: 26.4vw;
      position: absolute;
      top: 25.067vw;
      left: 25.2vw;
      img {
        width: 100%;
      }
    }
    .dec_box {
      width: 16vw;
      position: absolute;
      top: 53.067vw;
      right: 8.667vw;
      .dec_right {
        margin-top: 8vw;
      }
      .dec_left,
      .dec_right {
        width: 8vw;
        writing-mode: vertical-lr; //垂直方向，从左向右
        text-align: center;
        letter-spacing: 6px;
        float: left;
        font-size: 5.333vw;
        font-weight: bold;
        color: #425a0e;
      }
    }
    .learn_more {
      font-size: 2.667vw;
      font-weight: 300;
      color: #6c6c6b;
      position: absolute;
      bottom: 10.4vw;
      right: 10.133vw;
    }
  }
  .series_bg_a {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_b_a.jpg");
  }
  .series_bg_b {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_b_b.png");
  }
  .series_bg_c {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_b_c.jpg");
  }
  .series_bg_huacha {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_b_huacha.jpg");
  }
  .series_bg_d {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_series_b_d.jpg");
  }
  .video_box {
    width: 93.6vw;
    /* height: 67.333vw; */
    margin: 7.467vw auto;
    video {
      width: 100%;
      /* height: 100%; */
    }
  }
  .introduce_box {
    width: 93.6vww;
    height: 71.333vw;
    margin: 0 auto 6.667vw auto;
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/m_index_about_footer.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .introduce_inside {
      width: 37.733vw;
      height: 39.2vw;
      position: absolute;
      right: 2.533vw;
      top: 4.8vw;
      z-index: 998;
      color: white;
      .dec {
        font-size: 2.933vw;
        font-weight: 300;
        color: #ffffff;
        float: left;
        margin-top: 2.533vw;
        margin-left: 2.933vw;
        writing-mode: vertical-rl; //垂直方向，从左向右
        letter-spacing: 0.8vw;
      }
      .title {
        font-size: 4.267vw;
        font-weight: 600;
        color: #ffffff;
        writing-mode: vertical-lr; //垂直方向，从左向右
        text-align: center;
        letter-spacing: 0.8vw;
        float: right;
        margin-top: 2.533vw;
        margin-right: 1.733vw;
      }
    }
    .model {
      width: 37.733vw;
      height: 39.2vw;
      background: #fdf8f7;
      opacity: 0.33;
      position: absolute;
      right: 2.533vw;
      top: 4.8vw;
      z-index: 997;
    }
  }
}
</style>
